.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageBody {
  padding: 100px 0 0 0;
}

.userList {
  display: flex;
  align-items: center;
  min-width: 100vw;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 960px) {
  .pageBody {
    padding: 0;
  }
}