.searchBar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 0 0;
  border: 2px solid black;
  padding: 6px;
  border-radius: 5px;
}

.searchBar > input {
  border: none;
  outline: none;
}