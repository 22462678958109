.card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 20px;
  width: 100%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.card:hover {
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.36);
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
}

.card__name {
  color: #ea5031;
}

.card__picture {
  width: 30%;
  border: 2px solid #ccc;
  border-radius: 10px;
  margin: 1rem 0;
}

.card__button {
  background-color: #ea5031;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  user-select: none;
  margin-left: auto;
}

.card__button:hover {
  background-color: #d14a2d;
  text-decoration: underline;
}

.card__sectionTitle {
  font-weight: bold;
  text-decoration: underline;
}

.card__editInput {
  margin: 0 0 0 6px;
}

@media screen and (max-width: 960px) {
  .card {
    max-width: 100%;
    align-items: flex-start;
    text-align: left;
  }

  .card__picture {
    margin: 1rem auto;
  }

  .card__name {
    margin-left: auto;
    margin-right: auto;
  }
}